import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import "styles/blog.scss";
import Fade from "react-reveal/Fade";
import Head from "../components/head";

const BlogPage = () => {
  const blogData = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
              featuredImgUrl
              featuredImgAlt
            }
            fields {
              slug
            }
            timeToRead
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title="Blog" />
      <div className="blogs-container">
        <Fade bottom distance="0.2em">
          <ol className="blogs-list">
            {blogData.allMarkdownRemark.edges.map(edge => (
              <Link to={`/blog/${edge.node.fields.slug}`}>
                <li className="blogs-list-each">
                  <div
                    className="each-content"
                    style={{
                      backgroundImage: `url(${edge.node.frontmatter.featuredImgUrl})`,
                    }}
                  >
                    {/* <img className="featured-image" src={edge.node.frontmatter.featuredImgUrl} alt={edge.node.frontmatter.featuredImgAlt} /> */}
                    <div className="content-container">
                      <h2>{edge.node.frontmatter.title}</h2>
                      <div className="blogs-list-each-content">
                        <span>{edge.node.frontmatter.date}</span>
                        <span>{`${edge.node.timeToRead} min read`}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ol>
        </Fade>
      </div>
    </Layout>
  );
};

export default BlogPage;
